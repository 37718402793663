<template>
  <div>
    <div class="title">招聘中</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
    </div>

    <div class="counts_box">
      <div class="counts_box_item">
        <div>初筛</div>
        <el-divider direction="vertical"></el-divider>
        <div>
          <div>待初筛</div>
          <div>{{ statistics.status1 }}人</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div>
          <div>初筛通过</div>
          <div>{{ statistics.status2 }}人</div>
        </div>
      </div>
      <div class="counts_box_item">
        <div>面试</div>
        <el-divider direction="vertical"></el-divider>
        <div>
          <div>已安排面试</div>
          <div>{{ statistics.status3 }}人</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div>
          <div>已面试</div>
          <div>{{ statistics.status4 }}人</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div>
          <div>面试通过</div>
          <div>{{ statistics.status5 }}人</div>
        </div>
      </div>
      <!-- <div class="counts_box_item">
                <div>录用</div>
                <el-divider direction="vertical"></el-divider>
                <div>
                    <div>发Offer</div>
                    <div>{{ statistics.status6 }}人</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div>
                    <div>待入职</div>
                    <div>{{ statistics.status7 }}人</div>
                </div>
            </div> -->
    </div>

    <div class="interviewers_item" v-for="item in resumeList" :key="item.id">
      <div class="interviewers_item_post">
        <div>应聘：{{ item.job.name }}</div>
        <div v-if="item.status == 2">
          面试时间：{{
            item.job_interview ? item.job_interview.create_time_format : "无"
          }}
          {{ item.job_interview ? item.job_interview.status_value : "" }}
        </div>
        <div v-if="item.status == 6">
          发offer时间：{{
            item.job_interview
              ? item.job_interview_entry.create_time_format
              : "无"
          }}
          {{ item.job_interview ? item.job_interview_entry.status_value : "" }}
        </div>
      </div>
      <div class="interviewers_item_info">
        <el-image
          class="interviewers_item_info_avatar"
          :src="host + item.image"
          fit="cover"
        ></el-image>
        <div class="interviewers_item_info_data">
          <div class="interviewers_item_info_data_name">
            {{ item.realname }}
          </div>
          <div class="interviewers_item_info_data_box">
            <div>{{ item.degree }}</div>
            <el-divider direction="vertical"></el-divider>
            <div>{{ item.age }}岁</div>
            <el-divider direction="vertical"></el-divider>
            <div>{{ item.phone }}</div>
          </div>
          <div class="interviewers_item_info_data_exp">
            <div>
              工作经历:
              <div v-for="(item, index) in item.jobExpDup" :key="index">
                <p>
                  <span>{{ item.company }}</span>
                  <span>{{ item.department }}</span>
                  <span>{{ item.position }}</span>
                  <span>{{ item.industry }}</span>
                </p>
                <p>
                  <span>{{ item.entry_date }} 到 {{ item.leave_date }}</span>
                </p>
                <p>
                  <span>{{ item.work_desc }}</span>
                </p>
              </div>
            </div>
            <div>
              教育经历：
              <div v-for="(item, index) in item.eduExpDup" :key="index">
                <p>
                  <span>{{ item.school }}</span>
                  <span>{{ item.degree }}</span>
                  <span>{{ item.major }}</span>
                </p>
                <p>
                  <span>{{ item.entry_date }} 到 {{ item.leave_date }}</span>
                </p>
                <p>
                  <span>{{ item.work_desc }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="interviewers_item_btn">
          <template v-if="item.status === 1">
            <div class="custom_button plain no_select" @click="toPass(item, 2)">
              通过
            </div>
            <div class="custom_button plain no_select" @click="toOut(item, -1)">
              淘汰
            </div>
          </template>
          <template v-if="item.status === 2">
            <div
              class="custom_button plain no_select"
              @click="toInterview(item)"
            >
              邀请面试
            </div>
            <div class="custom_button plain no_select" @click="toOut(item, -1)">
              淘汰
            </div>
          </template>
          <template v-if="item.status === 3">
            <div class="custom_button plain no_select" @click="toPass(item, 5)">
              通过面试
            </div>
            <div class="custom_button plain no_select" @click="toOut(item, -1)">
              淘汰
            </div>
          </template>
          <!-- <template v-if="item.status === 4">
                        <div class="custom_button plain no_select">
                            通过
                        </div>
                        <div class="custom_button plain no_select">
                            拒绝
                        </div>
                    </template>
                    <template v-if="item.status === 5">
                        <div class="custom_button plain no_select">
                            发OFFER
                        </div>
                        <div class="custom_button plain no_select">
                            淘汰
                        </div>
                    </template>
                    <template v-if="item.status === 6">
                        <div class="custom_button plain no_select">
                            修改offer
                        </div>
                        <div class="custom_button plain no_select">
                            查看offer详情
                        </div>
                    </template>
                    <template v-if="item.status === 7">
                        <div class="custom_button plain no_select">
                            录入花名册
                        </div>
                        <div class="custom_button plain no_select">
                            查看offer
                        </div>
                    </template> -->
        </div>
      </div>
    </div>
    <div v-if="resumeList.length === 0" style="margin: 30px 20px">暂无数据</div>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <el-dialog title="邀请面试" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="form_interview"
        ref="form_interview"
        :rules="rules_interview"
      >
        <el-form-item label="候选人名称">
          <el-input v-model="form_interview.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="面试岗位：">
          <el-input
            v-model="form_interview.station"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="面试时间" prop="interview_time">
          <el-date-picker
            v-model="form_interview.interview_time"
            type="datetime"
            placeholder="选择日期时间"
            default-time="9:00:00"
            value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="面试官" prop="hr_id">
          <el-select
            v-model="form_interview.hr_id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入面试官搜索"
            :remote-method="remoteMethod"
            :loading="employee_loading"
          >
            <el-option
              v-for="item in employee_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="面试官联系方式" prop="mobile">
                    <el-select
                        v-model="form_interview.mobile"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入面试官手机号码搜索"
                        :remote-method="remoteMethod"
                        :loading="employee_loading"
                    >
                        <el-option
                            v-for="item in employee_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item label="面试地址" prop="interview_address">
          <el-input v-model="form_interview.interview_address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="custom_button no_select" @click="interviewSubmit">
          确 定
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      search: {
        keyword: "",
      },
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      resumeList: [],
      statistics: {},

      form_interview: {
        id: "",
        name: "",
        interview_time: null,
        hr_id: "",
        interview_address: "",
        station: "",
        mobile: "",
      },
      rules_interview: {
        interview_time: [
          {
            required: true,
            message: "请选择面试时间",
            trigger: "blur",
          },
        ],
        hr_id: [
          {
            required: true,
            message: "请选择面试官",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请选择面试官联系电话",
            trigger: "blur",
          },
        ],
        interview_address: [
          {
            required: true,
            message: "请填写面试地址",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      department_options: [],
      station_options: [],
      employee_options: [],
      employee_loading: false,
    };
  },
  created() {
    this.getRecruitmentStatistics()
      .then((res) => {
        console.log(res);
        this.statistics = res.data.data;
      })
      .catch((e) => {
        console.error(e);
      });

    this.getEnterpriseInfo()
      .then((res) => {
        console.log(res);
        this.form_interview.interview_address = res.data.data.info.address;
      })
      .catch((e) => {
        console.log(e);
      });

    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("enterprise", ["getEnterpriseInfo"]),
    ...mapActions("recruitment", [
      "getRecruitingList",
      "getRecruitmentStatistics",
      "getAduit",
      "getJobTeam",
      "postInterview",
    ]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        params.size = this.page.size;
        let loading = this.$loading();
        this.getRecruitingList(params)
          .then((res) => {
            console.log("lists", res);
            this.resumeList = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            console.log(e);
            this.$message.error(e.statusText || e.data.msg);
            this.$nextTick(() => {
              loading.close();
            });
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 通过
     */
    toPass(item, status) {
      this.$confirm(
        `确定通过 ${item.realname} ${item.job.name} 的面试吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.getAduit({ id: item.id, status: status })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.page.page = 1;
            this.loadData();
          })
          .catch((e) => {
            this.$message.error(e.data);
          });
      });
    },

    /**
     * 淘汰
     */
    toOut(item, status) {
      this.$confirm(`确定淘汰 ${item.realname} ${item.job.name} 吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.getAduit({ id: item.id, status: status })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.page.page = 1;
            this.loadData();
          })
          .catch((e) => {
            this.$message.error(e.data);
          });
      });
    },

    /**
     * 面试官
     */
    remoteMethod(query) {
      if (query !== "") {
        this.employee_loading = true;
        this.getJobTeam({
          page: 1,
          type: 2, // 面试官
          keywords: query,
        })
          .then((res) => {
            this.employee_loading = false;
            console.log(res);
            this.employee_options = res.data.data.list.map((item) => {
              return {
                label: item.staff.real_name,
                value: item.id,
              };
            });
          })
          .catch((e) => {
            this.employee_loading = false;
            console.error(e);
          });
      } else {
        this.employee_options = [];
      }
    },

    /**
     * 邀请面试
     */
    toInterview(item) {
      this.form_interview = {
        id: item.id,
        name: item.realname,
        interview_time: null,
        hr_id: "",
        interview_address: "",
        station: item.job.name,
        mobile: "",
      };
      this.dialogVisible = true;
    },

    interviewSubmit() {
      this.$refs.form_interview.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          this.dialogVisible = false;
          this.postInterview(this.form_interview)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }
}

.counts_box {
  display: flex;
  //   justify-content: space-between;

  .counts_box_item {
    display: flex;
    background-color: #ffffff;
    border: 1px solid #e1e6eb;
    box-shadow: 0px 6px 25px 0px rgba(223, 227, 236, 0.3);
    border-radius: 4px;
    padding: 18px;

    .el-divider {
      height: auto;
    }

    > div:not(.el-divider) {
      width: 130px;
      height: 100-36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #495060;

      > div:last-child {
        margin-top: 15px;
      }
    }

    > div:first-child {
      font-size: 16px;
      color: #1c2438;
      line-height: 30px;
    }
  }
}

.interviewers_item {
  margin: 20px 0;
  border: 1px solid #f0f4f8;
  border-radius: 5px;
  min-height: 200px;

  .interviewers_item_post {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background-color: #f5f7fa;
    border-radius: 5px 5px 0px 0px;
    font-size: 14px;
    color: #1c2438;
    line-height: 40px;
    padding: 0 25px;
  }

  .interviewers_item_info {
    display: flex;

    .interviewers_item_info_avatar {
      flex-shrink: 0;
      margin: 25px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .interviewers_item_info_data {
      width: 100%;
      padding: 25px 0;

      .interviewers_item_info_data_name {
        font-size: 20px;
        font-weight: bold;
        color: #1c2438;
      }

      .interviewers_item_info_data_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #495060;
        margin: 10px 0;
      }

      .interviewers_item_info_data_exp {
        display: flex;

        > div {
          flex: 1;
          font-size: 14px;
          color: #495060;

          > div {
            > p {
              > span:not(:last-child) {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .interviewers_item_btn {
      display: flex;
      margin: 25px;

      .custom_button {
        height: 32px;
        line-height: 32px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
